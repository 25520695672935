import React, { useEffect, useRef, useState } from "react";
import MenuLayouts from "../layouts/menu.layout";
import FooterLayouts from "../layouts/footer.layout";
import MenuVehiculosDetailComponent from "../components/vehiculos-detail/menu-vehiculos.component";
import BannerVehiculosComponent from "../components/vehiculos-detail/banner-vehiculos.component";
import DiseñoVehiculosComponent from "../components/vehiculos-detail/diseño-vehiculos.component";
import ModelosVehiculosComponent from "../components/vehiculos-detail/modelos-vehiculos.component";
import GaleriaVehiculosComponent from "../components/vehiculos-detail/galeria-vehiculos.component";
import GaleriaModalVehiculosComponent from "../components/vehiculos-detail/galeriaModal-vehiculos.component";
import CaracteristicasVehiculosComponent from "../components/vehiculos-detail/caracteristicas-vehiculos.component";
import TecnologiaVehiculosComponent from "../components/vehiculos-detail/tecnologia-vehiculos.component";
import VersionesVehiculosComponent from "../components/vehiculos-detail/versiones-vehiculos.component";
import ReservaVehiculosComponent from "../components/vehiculos-detail/reserva-vehiculos.component";
import ComunidadVehiculosComponent from "../components/vehiculos-detail/comunidad-vehiculos.component";
import OtrosVehiculosComponent from "../components/vehiculos-detail/otros-vehiculos.component";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CotizacionVehiculosComponent from "../components/vehiculos-detail/cotizacion-vehiculos.component";

/*eslint-disable */

const menu: any = [
  {
    modelo: "kona-gasolina",
    title: "KONA GASOLINA",
  },
  {
    modelo: "kona-hibrida",
    title: "KONA HÍBRIDA",
  },
  {
    modelo: "kona-n-line",
    title: "KONA N LINE",
  },
  {
    modelo: "kona-electrica",
    title: "KONA ELÉCTRICA",
  },
];

const banner: any = [
  {
    modelo: "kona-gasolina",
    title: "KONA Gasolina",
    src: "../assets/vehiculos/kona-gasolina/KONA_Gasolina.webp",
    ICSH: "#",
  },
  {
    modelo: "kona-hibrida",
    title: "KONA Híbrida",
    src: "../assets/vehiculos/kona-hibrida/Banner_KONA_HEV_Hibrida.webp",
    ICSH: "#",
  },
  {
    modelo: "kona-n-line",
    title: "KONA N Line",
    src: "../assets/vehiculos/kona-nline/Kona-HEV-NLINE-Banner.webp",
    ICSH: null,
  },
  {
    modelo: "kona-electrica",
    title: "KONA Eléctrica",
    src: "../assets/vehiculos/kona-electrica/banner-kona-electrica.webp",
    ICSH: null,
  },
];

const diseño: any = [
  {
    modelo: "kona-gasolina",
    title: "Más grande, atrevida y dinámica.",
    contenido: [
      {
        title: "Faros delanteros tipo LED",
        detalle:
          "La iluminación LED ofrece claridad y visibilidad superior a las luces halógenas tradicionales, permitiendo ver más lejos y con mayor claridad durante la noche o en condiciones de baja visibilidad.",
        src: "../assets/vehiculos/kona-gasolina/features/Faros_delanteros.webp",
        size: "small",
        class: "normal",
        type: "img",
      },
      {
        title: "Rines de 17 y 18 pulgadas",
        detalle:
          "Rin de 17 y 18 pulgadas en sus versiones Premium y Limited respectivamente; ambas versiones compuestas por rines de aleación, con una variante bitono para la versión Limited.",
        src: "../assets/vehiculos/kona-gasolina/features/rin-17-18.mp4",
        size: "small",
        class: "anormal",
        type: "video",
      },
      {
        title: "Rieles de equipaje",
        detalle:
          "Te ofrecemos dos rieles que recorren la extensión del techo de esta camioneta, permitiéndo incrementar la capacidad de transporte para el viaje.",
        src: "../assets/vehiculos/kona-gasolina/features/Rieles_porta_equipaje.webp",
        size: "small",
        class: "normal",
        type: "img",
      },
      {
        title: "Tablero de instrumentos y centro de entretenimiento",
        detalle:
          "Con una pantalla curva unificada en un recorrido horizontal de 12,3” dispones de una proyección intuitiva para el tablero de instrumentos junto al centro de entretenimiento; todo en una sola disposición para maximizar la eficiencia de concentración en la conducción.",
        src: "../assets/vehiculos/kona-gasolina/features/Tablero_de_instrumentos.webp",
        size: "large",
        class: "normal",
        type: "img",
      },
      {
        title: "Caja de velocidades automática",
        detalle:
          "Transmisión Variable Inteligente (Tipo IVT) para máxima eficiencia en la conducción.",
        src: "../assets/vehiculos/kona-gasolina/features/Doble_embrague.webp",
        size: "small",
        class: "normal",
        type: "img",
      },
      {
        title: "Cojinería en cuero y ecocuero",
        detalle:
          "Comodidad y una adecuada presentación; esto define a la cojinería interna de nuestra nueva KONA Gasolina, cuenta con acabadis de eco-cuero para la versión Premium y cuero para la versión Limited.",
        src: "../assets/vehiculos/kona-gasolina/features/Eco_cuero.webp",
        size: "small",
        class: "anormal",
        type: "img",
      },
    ],
  },
  {
    modelo: "kona-hibrida",
    title: "Conducir de manera sostenible nunca ha sido tan emocionante.",
    contenido: [
      {
        title: "Faros delanteros tipo LED",
        detalle:
          "La iluminación LED ofrece claridad y visibilidad superior a las luces halógenas tradicionales, permitiendo ver más lejos y con mayor claridad durante la noche o en condiciones de baja visibilidad.",
        src: "../assets/vehiculos/kona-gasolina/features/Faros_delanteros.webp",
        size: "small",
        class: "normal",
        type: "img",
      },
      {
        title: "Rines de 17 y 18 pulgadas",
        detalle:
          "Rin de 17 y 18 pulgadas en sus versiones Premium y Limited respectivamente; ambas versiones compuestas por rines de aleación, con una variante bitono para la versión Limited.",
        src: "../assets/01_Home/Ioniq 5Optimized.mp4",
        size: "small",
        class: "anormal",
        type: "video",
      },
      {
        title: "Rieles de equipaje",
        detalle:
          "Te ofrecemos dos rieles que recorren la extensión del techo de esta camioneta, permitiéndo incrementar la capacidad de transporte para el viaje.",
        src: "../assets/vehiculos/kona-gasolina/features/Rieles_porta_equipaje.webp",
        size: "small",
        class: "normal",
        type: "img",
      },
      {
        title: "Tablero de instrumentos y centro de entretenimiento",
        detalle:
          "Con una pantalla curva unificada en un recorrido horizontal de 12,3” dispones de una proyección intuitiva para el tablero de instrumentos junto al centro de entretenimiento; todo en una sola disposición para maximizar la eficiencia de concentración en la conducción.",
        src: "../assets/vehiculos/kona-gasolina/features/Tablero_de_instrumentos.webp",
        size: "large",
        class: "normal",
        type: "img",
      },
      {
        title: "Caja de velocidades automática",
        detalle:
          "Transmisión Variable Inteligente (Tipo IVT) para máxima eficiencia en la conducción.",
        src: "../assets/01_Home/Ioniq 5Optimized.mp4",
        size: "small",
        class: "normal",
        type: "video",
      },
      {
        title: "Cojinería en cuero y ecocuero",
        detalle:
          "Comodidad y una adecuada presentación; esto define a la cojinería interna de nuestra nueva KONA Gasolina, cuenta con acabadis de eco-cuero para la versión Premium y cuero para la versión Limited.",
        src: "../assets/vehiculos/kona-gasolina/features/Eco_cuero.webp",
        size: "small",
        class: "anormal",
        type: "img",
      },
    ],
  },
  {
    modelo: "kona-n-line",
    title: "Rendimiento híbrido con espíritu deportivo",
    contenido: [
      {
        title: "Una potente primera impresión",
        detalle:
          "El exclusivo diseño del parachoques delantero, la estructura N Line y los detalles en color negro integran toda la esencia deportiva Hyundai para crear una imagen inconfundible y que de seguro atraerá miradas.",
        src: "../assets/vehiculos/kona-nline/features/kona-nline-exterior.webp",
        size: "large",
        class: "normal",
        type: "img",
      },
      {
        title: "Rines de 18 pulgadas",
        detalle:
          "Esta versión de KONA está compuesta por rines de aleación, con un variante bitono y un diseño exclusivo N Line que impactará en su diseño aerodinámico.",
        src: "../assets/vehiculos/kona-nline/features/kona-nline-rines_AI.webp",
        size: "small",
        class: "normal",
        type: "img",
      },
      {
        title: "Protección con estilo",
        detalle:
          "El bocel lateral inferior cuida las partes vulnerables de la KONA, como los neumáticos y los parachoques, previniendo daños por impacto. Además, está diseñado para reducir la resistencia al viento, lo que a su vez mejora la eficiencia y reduce el ruido del viento durante la conducción.",
        src: "../assets/vehiculos/kona-nline/features/kona-nline-bocel.webp",
        size: "small",
        class: "anormal",
        type: "img",
      },
      {
        title: "Contra el viento y más",
        detalle:
          "Este alerón con diseño sport tiene como función optimizar la resistencia del vehículo frente al aire al desplazarse, generando una apariencia y una aerodinámica única en la nueva KONA HEV N Line, haciendo inmejorable cada momento de aceleración.",
        src: "../assets/vehiculos/kona-nline/features/kona-nline-spoiler.webp",
        size: "small",
        class: "normal",
        type: "img",
      },
      {
        title: "Tubo de escape doble",
        detalle:
          "KONA N Line muestra su inspiración deportiva y de rendimiento en cada detalle. El tubo de escape doble cromado resalta el aspecto deportivo de este modelo.",
        src: "../assets/vehiculos/kona-nline/features/kona-nline-tubo-escape.webp",
        size: "small",
        class: "anormal",
        type: "img",
      },
    ],
  },
  {
    modelo: "kona-electrica",
    title: "¡El futuro es ahora!",
    contenido: [
      {
        title: "Luces LED",
        detalle:
          "La cautivadora parrilla cerrada fluye sobre los distintivos faros LED y las afiladas luces diurnas, extendiéndose elegantemente alrededor de los bordes.",
        src: "../assets/vehiculos/kona-electrica/features/kona-electrica-luces-led.webp",
        size: "small",
        class: "normal",
        type: "img",
      },
      {
        title: "Seguridad mejorada",
        detalle:
          "Con Hyundai SmartSense, nuestros sistemas de asistencia a la conducción avanzados, puedes disfrutar de tecnología y funciones de conducción innovadoras para disfrutar de más tranquilidad al volante.",
        src: "../assets/vehiculos/kona-electrica/features/kona-electrica-seguridad.webp",
        size: "small",
        class: "anormal",
        type: "img",
      },
    ],
  },
];

const modelos: any = [
  {
    modelo: "kona-gasolina",
    title: "KONA Gasolina",
    modelos: [
      {
        name: "Premium",
        precio: "X00.000.000",
        colores: [
          {
            color: "Amarillo Neon",
            codigo: "#AFD653",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-amarillo-neon.webp",
          },
          {
            color: "Azul denim",
            codigo: "#292E45",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-azul-denim.webp",
          },
          {
            color: "Blanco Atlas",
            codigo: "#D3D3D3",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-blanco-atlas.webp",
          },
          {
            color: "Gris metalizado",
            codigo: "#AAAAAA",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-ciber-gris-metalizado.webp",
          },
          {
            color: "Gris eco perlado",
            codigo: "#6A695D",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-gris-eco-perlado.webp",
          },
          {
            color: "Negro abismo perlado",
            codigo: "#222222",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-negro-abismo-perlado.webp",
          },
          {
            color: "Rojo eléctrico",
            codigo: "#600101",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-rojo-electrico.webp",
          },
          {
            color: "Verde miraje",
            codigo: "#697E69",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-verde-miraje.webp",
          },
        ],
        caracteristicas: [
          {
            title: "Rines en aluminio",
            detalle: "Diámetro de 17 pulgadas.",
          },
          {
            title: "Carrocería bitono",
            detalle: "con techo negro.",
          },
          {
            title: "Cojinería",
            detalle: "con eco cuero.",
          },
          {
            title: "SmartSense",
            detalle:
              "Asistencia para controlar las pendientes en ascenso, descenso y la velocidad.",
          },
        ],
      },
      {
        name: "Limited",
        precio: "113.990.000",
        colores: [
          {
            color: "Amarillo Neon",
            codigo: "#AFD653",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-amarillo-neon.webp",
          },
          {
            color: "Azul denim",
            codigo: "#292E45",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-azul-denim.webp",
          },
          {
            color: "Blanco Atlas",
            codigo: "#D3D3D3",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-blanco-atlas.webp",
          },
          {
            color: "Gris metalizado",
            codigo: "#AAAAAA",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-ciber-gris-metalizado.webp",
          },
          {
            color: "Gris eco perlado",
            codigo: "#6A695D",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-gris-eco-perlado.webp",
          },
          {
            color: "Negro abismo perlado",
            codigo: "#222222",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-negro-abismo-perlado.webp",
          },
          {
            color: "Rojo eléctrico",
            codigo: "#600101",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-rojo-electrico.webp",
          },
          {
            color: "Verde miraje",
            codigo: "#697E69",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-verde-miraje.webp",
          },
        ],
        caracteristicas: [
          {
            title: "Rines en aluminio bitono",
            detalle: "Diámetro de 18 pulgadas.",
          },
          {
            title: "Sunroof",
            detalle: "Para complementar el paisaje.",
          },
          {
            title: "Cojinería",
            detalle: "Con cuero.",
          },
          {
            title: "SmartSense",
            detalle: "9 asistentes de seguridad para acompañar tu viaje.",
          },
        ],
      },
    ],
  },
  {
    modelo: "kona-hibrida",
    title: "KONA Híbrida",
    modelos: [
      {
        name: "Premium",
        precio: "X00.000.000",
        colores: [
          {
            color: "Amarillo Neon",
            codigo: "#AFD653",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-amarillo-neon.webp",
          },
          {
            color: "Azul denim",
            codigo: "#292E45",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-azul-denim.webp",
          },
          {
            color: "Blanco Atlas",
            codigo: "#D3D3D3",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-blanco-atlas.webp",
          },
          {
            color: "Gris metalizado",
            codigo: "#AAAAAA",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-ciber-gris-metalizado.webp",
          },
          {
            color: "Gris eco perlado",
            codigo: "#6A695D",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-gris-eco-perlado.webp",
          },
          {
            color: "Negro abismo perlado",
            codigo: "#222222",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-negro-abismo-perlado.webp",
          },
          {
            color: "Rojo eléctrico",
            codigo: "#600101",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-rojo-electrico.webp",
          },
          {
            color: "Verde miraje",
            codigo: "#697E69",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-verde-miraje.webp",
          },
        ],
        caracteristicas: [
          {
            title: "Rines en aluminio",
            detalle: "Diámetro de 17 pulgadas.",
          },
          {
            title: "Carrocería bitono",
            detalle: "con techo negro.",
          },
          {
            title: "Cojinería",
            detalle: "con eco cuero.",
          },
          {
            title: "SmartSense",
            detalle:
              "Asistencia para controlar las pendientes en ascenso, descenso y la velocidad.",
          },
        ],
      },
      {
        name: "Limited",
        precio: "113.990.000",
        colores: [
          {
            color: "Amarillo Neon",
            codigo: "#AFD653",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-amarillo-neon.webp",
          },
          {
            color: "Azul denim",
            codigo: "#292E45",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-azul-denim.webp",
          },
          {
            color: "Blanco Atlas",
            codigo: "#D3D3D3",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-blanco-atlas.webp",
          },
          {
            color: "Gris metalizado",
            codigo: "#AAAAAA",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-ciber-gris-metalizado.webp",
          },
          {
            color: "Gris eco perlado",
            codigo: "#6A695D",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-gris-eco-perlado.webp",
          },
          {
            color: "Negro abismo perlado",
            codigo: "#222222",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-negro-abismo-perlado.webp",
          },
          {
            color: "Rojo eléctrico",
            codigo: "#600101",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-rojo-electrico.webp",
          },
          {
            color: "Verde miraje",
            codigo: "#697E69",
            src: "../assets/vehiculos/kona-gasolina/colores/kona-verde-miraje.webp",
          },
        ],
        caracteristicas: [
          {
            title: "Rines en aluminio bitono",
            detalle: "Diámetro de 18 pulgadas.",
          },
          {
            title: "Sunroof",
            detalle: "Para complementar el paisaje.",
          },
          {
            title: "Cojinería",
            detalle: "Con cuero.",
          },
          {
            title: "SmartSense",
            detalle: "9 asistentes de seguridad para acompañar tu viaje.",
          },
        ],
      },
    ],
  },
  {
    modelo: "kona-n-line",
    title: "KONA N Line",
    modelos: [
      {
        name: "Premium",
        precio: "161.990.000",
        colores: [
          {
            color: "Azul Denim",
            codigo: "#292C38",
            src: "../assets/vehiculos/kona-nline/colores/kona-azul-denim.webp",
          },
          {
            color: "Azul Metal",
            codigo: "#69687D",
            src: "../assets/vehiculos/kona-nline/colores/kona-azul-metal.webp",
          },
          {
            color: "Blanco Atlas",
            codigo: "#E2E2E2",
            src: "../assets/vehiculos/kona-nline/colores/kona-blanco-atlas.webp",
          },
          {
            color: "Ciber Gris Metalizado",
            codigo: "#9A9A98",
            src: "../assets/vehiculos/kona-nline/colores/kona-ciber-gris-metalizado.webp",
          },
          {
            color: "Gris Eco Perlado",
            codigo: "#5D5F61",
            src: "../assets/vehiculos/kona-nline/colores/kona-gris-eco-perlado.webp",
          },
          {
            color: "Naranja Boreal",
            codigo: "#A51808",
            src: "../assets/vehiculos/kona-nline/colores/kona-naranja-boreal.webp",
          },
          {
            color: "Negro Abismo Perlado",
            codigo: "#0E0E10",
            src: "../assets/vehiculos/kona-nline/colores/kona-negro-abismo-perlado.webp",
          },
          {
            color: "Rojo Eléctrico Metálico",
            codigo: "#790808",
            src: "../assets/vehiculos/kona-nline/colores/kona-rojo-electrico-metalico.webp",
          },
          {
            color: "Verde Miraje",
            codigo: "#818F8B",
            src: "../assets/vehiculos/kona-nline/colores/kona-verde-miraje.webp",
          },
        ],
        caracteristicas: [
          {
            title: "Rines en aluminio bitono",
            detalle: "Diámetro de 18 pulgadas.",
          },
          {
            title: "Diseño deportivo",
            detalle: "Con alerón aerodinámico y tubo de escape cromado.",
          },
          {
            title: "Cojinería",
            detalle: "con cuero.",
          },
          {
            title: "SmartSense",
            detalle:
              "Asistencia para controlar las pendientes en ascenso, descenso y la velocidad.",
          },
        ],
      },
    ],
  },
  {
    modelo: "kona-electrica",
    title: "KONA Eléctrica",
    modelos: [
      {
        name: "Premium",
        precio: "159.990.000",
        colores: [
          {
            color: "Azul Surfy",
            codigo: "#0179BE",
            src: "../assets/vehiculos/kona-electrica/colores/kona-azul-surfy.webp",
          },
          {
            color: "Blanco Atlas",
            codigo: "#E8E8E8",
            src: "../assets/vehiculos/kona-electrica/colores/kona-blanco-atlas.webp",
          },
          {
            color: "Ciber Gris",
            codigo: "#A4B1B3",
            src: "../assets/vehiculos/kona-electrica/colores/kona-ciber-Gris.webp",
          },
          {
            color: "Gris Astral",
            codigo: "#7E8484",
            src: "../assets/vehiculos/kona-electrica/colores/kona-gris-astral.webp",
          },
          {
            color: "Rojo Electrico",
            codigo: "#AA1618",
            src: "../assets/vehiculos/kona-electrica/colores/kona-rojo-electrico.webp",
          },
          {
            color: "Verde Jungla",
            codigo: "#668783",
            src: "../assets/vehiculos/kona-electrica/colores/kona-verde-jungla.webp",
          },
        ],
        caracteristicas: [
          {
            title: "Rines en aluminio bitono",
            detalle: "Diámetro de 18 pulgadas.",
          },
          {
            title: "Diseño deportivo",
            detalle: "Con alerón aerodinámico y tubo de escape cromado.",
          },
          {
            title: "Cojinería",
            detalle: "con cuero.",
          },
          {
            title: "SmartSense",
            detalle:
              "Asistencia para controlar las pendientes en ascenso, descenso y la velocidad.",
          },
        ],
      },
    ],
  },
];

const galeria: any = [
  "../assets/kona-gasolina/colmena1.webp",
  "../assets/kona-gasolina/colmena2.webp",
  "../assets/kona-gasolina/colmena3.webp",
  "../assets/kona-gasolina/colmena4.webp",
  "../assets/kona-gasolina/colmena5.webp",
  "../assets/kona-gasolina/colmena6.webp",
  "../assets/kona-gasolina/colmena7.webp",
];

const comunidad: any = [
  "../assets/comunidad_hyundai/comunidad_2.webp",
  "../assets/comunidad_hyundai/comunidad_4.webp",
  "../assets/comunidad_hyundai/comunidad_1.webp",
  "../assets/comunidad_hyundai/comunidad_3.webp",
];

const caracteristicas: any = [
  {
    src: "../assets/vehiculos/kona-gasolina/tecnologia/6-Airbags.webp",
    title: "6 airbags",
    descripcion:
      "Están ubicados estratégicamente alrededor de los dos niveles del habitáculo general para todos los pasajeros (dos frontales: uno para el piloto y otro para el copiloto, dos tipos cortina y dos laterales).",
  },
  {
    src: "../assets/vehiculos/kona-gasolina/tecnologia/Camara-Reversa.webp",
    title: "Cámara de reversa con guías dinámicas",
    descripcion:
      "Cámara de reversa dotada de guías dinámicas, para un correcto dimensionamiento del espacio seguro a la hora de parquear. Gracias al modo adicional de visualización que enfoca su vista hacia el piso, podrás ver cualquier obstáculo o peligro que se encuentre en tu camino mientras retrocedes.",
  },
  {
    src: "../assets/vehiculos/kona-gasolina/tecnologia/Modo-Conduccion.webp",
    title: "Modos de manejo: Eco, Sport  Nieve",
    descripcion:
      "Cuenta con 3 modos de manejo para adaptarse a las necesidades y preferencias de conductor (ECO, SPORT y NIEVE) asegurando que cada experiencia al volante sea personal, eficiente y sobre todo, divertida.",
  },
  {
    src: "../assets/vehiculos/kona-gasolina/tecnologia/Sensor-Parqueo.webp",
    title: "Sensores de parqueo",
    descripcion:
      "Recibes una visión en tiempo real que cuenta con 8 sensores independientes, los cuales permiten construir una imagen abierta y realista de la parte frontal y trasera a la hora de parquear; asegurando la finalización eficiente y exitosa de tu destino.",
  },
];

const tecnologias: any = [
  {
    src: "../assets/vehiculos/kona-gasolina/smartsense/LKA_LFA.webp",
    title: "LKA y LFA",
    descripcion: "Asistentes de mantenimiento y seguimiento de carril.",
  },
  {
    src: "../assets/vehiculos/kona-gasolina/smartsense/HaC.webp",
    title: "HaC",
    descripcion: "Asistente de ascenso de pendientes.",
  },
  {
    src: "../assets/vehiculos/kona-gasolina/smartsense/SCC.webp",
    title: "SCC",
    descripcion: "Control de crucero inteligente.",
  },
  {
    src: "../assets/vehiculos/kona-gasolina/smartsense/ESC.webp",
    title: "ESC",
    descripcion: "Control electrónico de estabilidad.",
  },
  {
    src: "../assets/vehiculos/kona-gasolina/smartsense/BCA.webp",
    title: "BCA",
    descripcion: "Asistente de colisión en punto ciego trasero.",
  },
  {
    src: "../assets/vehiculos/kona-gasolina/smartsense/FCA.webp",
    title: "FCA",
    descripcion: "Asistente de colisión frontal.",
  },
];

const versiones: any = [
  {
    src: "../assets/00_Versiones/kona_hibrida.webp",
    title: "KONA Híbrida",
    precio: "139.990.000",
    motor: "Motor: 1.6L Gasolina y Eléctrico",
    transmision: "Transmisión: 6AT de doble embrague",
    garantia: "Garantía X años o X00.000 Km",
    ver: "/vehiculos/kona-hibrida",
    cotiza: "#",
  },
  {
    src: "../assets/00_Versiones/kona_nline.webp",
    title: "KONA Híbrida N Line",
    precio: "161.990.000",
    motor: "Motor: 1.6L Gasolina y Eléctrico",
    transmision: "Transmisión: 6AT de doble embrague",
    garantia: "Garantía 7 años o 140.000 Km",
    ver: "/vehiculos/kona-n-line",
    cotiza: "#",
  },
  {
    src: "../assets/00_Versiones/kona_electrica.webp",
    title: "KONA Eléctrica",
    precio: "159.990.000",
    motor: "Motor: 100 Kw / 395Nm",
    transmision: "Tipo de transmisión",
    garantia: "Garantía 5 años o 100.000 Km",
    ver: "/vehiculos/kona-electrica",
    cotiza: "#",
  },
  {
    src: "../assets/00_Versiones/kona_gasolina.webp",
    title: "KONA Gasolina",
    precio: "113.990.000",
    motor: "Motor: 2.0 L de 147 hp",
    transmision: "Botonera de cambios E-Shift",
    garantia: "Garantía 7 años o 140.000 Km",
    ver: "/vehiculos/kona-gasolina",
    cotiza: "#",
  },
];

const vehiculos: any = [
  {
    modelo: "kona-gasolina",
    title: "KONA Gasolina",
    src: "../assets/00_Reservas/Gasolina.webp",
    precio: "1.000.000",
    reserva: "#",
  },
  {
    modelo: "kona-hibrida",
    title: "KONA Híbrida",
    src: "../assets/00_Reservas/hibrida.webp",
    precio: "1.000.000",
    reserva: "#",
  },
  {
    modelo: "kona-n-line",
    title: "KONA N Line",
    src: "../assets/00_Reservas/Nline.webp",
    precio: "1.000.000",
    reserva: "#",
  },
  {
    modelo: "kona-electrica",
    title: "KONA Eléctrica",
    src: "../assets/00_Reservas/electrica.webp",
    precio: "1.000.000",
    reserva: "#",
  },
];


const VehiculosDetailPage: React.FC = () => {
  const diseñoRef = useRef<HTMLDivElement>(null);
  const versionesRef = useRef<HTMLDivElement>(null);
  const galeriaRef = useRef<HTMLDivElement>(null);
  const tecnologiaRef = useRef<HTMLDivElement>(null);
  const modelosRef = useRef<HTMLDivElement>(null);
  const fichaRef = useRef<HTMLDivElement>(null);
  const comparaRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const { vehiculo } = useParams();
  const [selectedGalery, setSelectedGalery] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState("diseño");

  // Función para desplazar a la sección correspondiente
  const scrollToSection = (
    sectionRef: React.RefObject<HTMLDivElement>,
    menos: number
  ) => {
    if (sectionRef.current) {
      window.scrollTo({
        top: sectionRef.current.offsetTop - menos,
        behavior: "smooth",
      });
    }
  };

  // Manejar el cambio de URL para hacer scroll
  useEffect(() => {
    const hash = location.hash;
    if (hash === "#diseno") {
      scrollToSection(diseñoRef, 150);
    } else if (hash === "#versiones") {
      scrollToSection(versionesRef, 60);
    } else if (hash === "#galeria") {
      scrollToSection(galeriaRef, 140);
    } else if (hash === "#tecnologia") {
      scrollToSection(tecnologiaRef, 150);
    } else if (hash === "#modelos") {
      scrollToSection(modelosRef, 100);
    }
  }, [location]);

  return (
    <>
      <MenuLayouts className="home-page" active="vehiculo" />
      <div className="home-page">
        <div className="home-page-mascara"></div>
        <MenuVehiculosDetailComponent
          selectedMenu={selectedMenu}
          navigate={navigate}
          setSelectedMenu={setSelectedMenu}
          data={menu.find((item: any) => item.modelo === vehiculo)}
        />
        <BannerVehiculosComponent
          banner={banner.find((item: any) => item.modelo === vehiculo)}
        />
        <CotizacionVehiculosComponent vehiculo={vehiculos.find((item: any) => item.modelo === vehiculo)} />
        <DiseñoVehiculosComponent
          diseño={diseño.find((item: any) => item.modelo === vehiculo)}
          ref={diseñoRef}
        />
        <ModelosVehiculosComponent
          modelos={
            modelos.find((item: any) => item.modelo === vehiculo).modelos
          }
          ref={versionesRef}
        />
        <GaleriaVehiculosComponent
          selectedGalery={selectedGalery}
          setSelectedGalery={setSelectedGalery}
          galeria={galeria}
          ref={galeriaRef}
        />
        <GaleriaModalVehiculosComponent
          selectedGalery={selectedGalery}
          setSelectedGalery={setSelectedGalery}
          galeria={galeria}
        />
        <CaracteristicasVehiculosComponent caracteristicas={caracteristicas} />
        <TecnologiaVehiculosComponent
          tecnologias={tecnologias}
          ref={tecnologiaRef}
        />
        <VersionesVehiculosComponent versiones={versiones} ref={modelosRef} />
        <ReservaVehiculosComponent
          vehiculo={vehiculos.find((item: any) => item.modelo === vehiculo)}
        />
        <ComunidadVehiculosComponent comunidad={comunidad} />
        <OtrosVehiculosComponent />
      </div>
      <FooterLayouts />
    </>
  );
};

export default VehiculosDetailPage;
