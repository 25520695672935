import React from "react";
import MenuLayouts from "../layouts/menu.layout";
import BannerComponent from "../components/home/banner.component";
import PortafolioComponent from "../components/home/portafolio.component";
import BlogComponent from "../components/home/blog.component";
import OtherPageComponent from "../components/home/other_page.component";
import SuscribeComponent from "../components/home/suscribe.component";
import FooterLayouts from "../layouts/footer.layout";

const HomePage: React.FC = () => {
  return (
    <>
      <MenuLayouts className="home-page" active=""/>
      <div className="home-page">
        <div className="home-page-mascara"></div>
        <BannerComponent />
        <PortafolioComponent />
        <BlogComponent />
        <OtherPageComponent />
        <SuscribeComponent />
      </div>
      <FooterLayouts />
    </>
  );
};

export default HomePage;
