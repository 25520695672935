import React, { useState } from "react";

/*eslint-disable */

declare const window: any;

interface CaracteristicasVehiculosComponentProps {
  caracteristicas: any;
}

const CaracteristicasVehiculosComponent: React.FC<
  CaracteristicasVehiculosComponentProps
> = ({ caracteristicas }) => {
  const [currentIndex, setCurrentIndex] = useState(1); // Inicialmente se muestra la imagen en el centro

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : caracteristicas.length - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < caracteristicas.length - 1 ? prevIndex + 1 : 0
    );
  };

  console.log(currentIndex);
  

  return (
    <div className="caracteristica-vehiculos-detail-component">
      <div className="btn-carrousel-caracteristica-vehiculos-detail-component prev" onClick={handlePrev}>
        <img src="../assets/01_Home/Icon_Left.svg" alt="" />
      </div>
      <div
        className="carrousel-caracteristica-vehiculos-detail-component"
        style={{
          transform:
            currentIndex === 0
              ? `translateX(${25}%)`
              : `translateX(-${(currentIndex * 25) === 25 ? currentIndex * 25 : (currentIndex * 25) === 50 ? currentIndex * 25 + 25: (currentIndex * 50 + 25) - 50}%)`,
        }}
      >
        {caracteristicas.map((item: any, index: any) => (
          <div className={`item-caracteristica-vehiculos-detail-component`}>
            <img src={item.src} alt="" />
          </div>
        ))}
      </div>
      <div className="btn-carrousel-caracteristica-vehiculos-detail-component next" onClick={handleNext}>
        <img src="../assets/01_Home/Icon_Right.svg" alt="" />
      </div>
      <div className="info-caracteristica-vehiculos-detail-component">
        <div className="title-caracteristica-vehiculos-detail-component">
          {caracteristicas[currentIndex].title}
        </div>
        <div className="descripcion-caracteristica-vehiculos-detail-component">
          {caracteristicas[currentIndex].descripcion}
        </div>
        <div className="index-caracteristica-vehiculos-detail-component">
          {caracteristicas.map((item: any, index: number) => 
            <div className={`${index === currentIndex ? "active" : ""}`} onClick={() => setCurrentIndex(index)}></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CaracteristicasVehiculosComponent;
