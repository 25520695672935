import React, { useState } from "react";
import MenuLayouts from "../layouts/menu.layout";
import FooterLayouts from "../layouts/footer.layout";
import SimuladorComponent from "../components/simulador/simulador.component";
import DatosComponent from "../components/simulador/datos.component";

/*eslint-disable */

const SimuladorPage: React.FC = () => {
  const [modelo, setModelo] = useState("");
  const [modelos, setModelos] = useState([
    {
      modelo: "kona-gasolina",
      title: "KONA Gasolina",
      src: "../assets/00_Reservas/Gasolina.webp",
      precio: "1.000.000",
      reserva: "#",
      versiones: ["Premium", "Limited"],
    },
    {
      modelo: "kona-hibrida",
      title: "KONA Híbrida",
      src: "../assets/00_Reservas/hibrida.webp",
      precio: "1.000.000",
      reserva: "#",
      versiones: ["Premium", "Limited"],
    },
    {
      modelo: "kona-n-line",
      title: "KONA N Line",
      src: "../assets/00_Reservas/Nline.webp",
      precio: "1.000.000",
      reserva: "#",
      versiones: ["Premium"],
    },
    {
      modelo: "kona-electrica",
      title: "KONA Eléctrica",
      src: "../assets/00_Reservas/electrica.webp",
      precio: "1.000.000",
      reserva: "#",
      versiones: [""],
    },
  ]);
  const [version, setVersion] = useState("");
  const [politica_1, setPolitica_1] = useState(false);
  const [politica_2, setPolitica_2] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0); // Inicialmente se muestra la imagen en el centro
  const [cuotaInicial, setCuotaInicial] = useState("0");
  const [plazo, setPlazo] = useState(72);
  const [frecuenciaPago, setFrecuenciaPago] = useState("m");
  const [diaPago, setDiaPago] = useState(17);
  const [tipoPlan, setTipoPlan] = useState("Tradicional");
  const [cuotaExtra, setCuotaExtra] = useState("0");
  return (
    <>
      <MenuLayouts className="home-page" active="vehiculo" />
      <div className="home-page">
        <div className="home-page-mascara"></div>

        <div className="simulador-component">
          <div
            className="carrousel-simulador-component"
            style={{
              transform: `translateX(-${currentIndex * 100}%)`,
            }}
          >
            <SimuladorComponent
              modelo={modelo}
              setModelo={setModelo}
              modelos={modelos}
              setModelos={setModelos}
              version={version}
              setVersion={setVersion}
              politica_1={politica_1}
              setPolitica_1={setPolitica_1}
              politica_2={politica_2}
              setPolitica_2={setPolitica_2}
              setCurrentIndex={setCurrentIndex}
            />
            <DatosComponent
              modelo={modelo}
              modelos={modelos}
              version={version}
              setCurrentIndex={setCurrentIndex}
              cuotaInicial={cuotaInicial}
              setCuotaInicial={setCuotaInicial}
              plazo={plazo}
              setPlazo={setPlazo}
              frecuenciaPago={frecuenciaPago}
              setFrecuenciaPago={setFrecuenciaPago}
              diaPago={diaPago}
              setDiaPago={setDiaPago}
              tipoPlan={tipoPlan}
              setTipoPlan={setTipoPlan}
              cuotaExtra={cuotaExtra}
              setCuotaExtra={setCuotaExtra}
            />
          </div>
        </div>
      </div>
      <FooterLayouts />
    </>
  );
};

export default SimuladorPage;
