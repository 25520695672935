import React from "react";

/*eslint-disable */

declare const window: any;

interface MenuVehiculosDetailComponentProps {
  selectedMenu: any
  navigate: any
  setSelectedMenu: any
  data: any
}

const MenuVehiculosDetailComponent: React.FC<
  MenuVehiculosDetailComponentProps
> = ({selectedMenu, navigate, setSelectedMenu, data}) => {
  return (
    <div className="menu-vehiculos-detail-component">
      <div className="title-menu-vehiculos-detail-component">{data.title}</div>
      <div className="content-menu-vehiculos-detail-component">
        <div className={`individual-content-menu-vehiculos-detail-component ${selectedMenu === "diseño" ? "active" : ""}`} onClick={() => {navigate("#diseno"), setSelectedMenu("diseño")}}>
          Diseño
        </div>
        <div className={`individual-content-menu-vehiculos-detail-component ${selectedMenu === "versiones" ? "active" : ""}`} onClick={() => {navigate("#versiones"), setSelectedMenu("versiones")}}>
          Versiones
        </div>
        <div className={`individual-content-menu-vehiculos-detail-component ${selectedMenu === "galeria" ? "active" : ""}`} onClick={() => {navigate("#galeria"), setSelectedMenu("galeria")}}>
          Galería
        </div>
        <div className={`individual-content-menu-vehiculos-detail-component ${selectedMenu === "tecnologia" ? "active" : ""}`} onClick={() => {navigate("#tecnologia"), setSelectedMenu("tecnologia")}}>
          Tecnología
        </div>
        <div className={`individual-content-menu-vehiculos-detail-component ${selectedMenu === "modelos" ? "active" : ""}`} onClick={() => {navigate("#modelos"), setSelectedMenu("modelos")}}>
          Modelos
        </div>
        <div className={`individual-content-menu-vehiculos-detail-component ${selectedMenu === "ficha" ? "active" : ""}`} onClick={() => {navigate("#ficha"), setSelectedMenu("ficha")}}>
          Ficha técnica
        </div>
        <div className={`individual-content-menu-vehiculos-detail-component ${selectedMenu === "compara" ? "active" : ""}`} onClick={() => {navigate("#compara"), setSelectedMenu("compara")}}>
          Compara
        </div>
      </div>
      <div className="opciones-menu-vehiculos-detail-component">
        <div className="simulador-opciones-menu-vehiculos-detail-component">
          Simula tu cuota
        </div>
        <div className="cotiza-opciones-menu-vehiculos-detail-component">
          Cotiza
        </div>
      </div>
    </div>
  );
};

export default MenuVehiculosDetailComponent;
