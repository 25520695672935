import React, { useState, useEffect } from "react";
import axios from "axios";

/*eslint-disable */

const SuscribeComponent: React.FC = () => {
  const [email, setEmail] = useState("");
  const [act1, setAct1] = useState(false);
  const [act2, setAct2] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    // Deshabilitar el botón si no están ambos checkboxes marcados
    setIsButtonDisabled(!(act1 && act2));
  }, [act1, act2]);

  const handleSubscribe = async () => {
    if (!email) {
      alert("Por favor, ingresa tu correo electrónico.");
      return;
    }

    try {
      const response = await axios.post(
        "https://8d6cxglsnh.execute-api.us-east-1.amazonaws.com/api/suscribe",
        {
          email,
          act1,
          act2,
        }
      );
      alert("Suscripción exitosa!");
      // Limpiar los campos después de la suscripción exitosa
      setEmail("");
      setAct1(false);
      setAct2(false);
    } catch (error) {
      console.error("Error al suscribirse:", error);
      alert(
        "Hubo un error al intentar suscribirte. Por favor, inténtalo de nuevo."
      );
    }
  };

  return (
    <div className="suscribe-home-component">
      <div className="content-suscribe-home-component">
        <div className="left-content-suscribe-home-component">
          <div className="title-left-content-suscribe-home-component">
            Sé parte <br /> de la experiencia Hyundai
          </div>
          <div className="descripcion-left-content-suscribe-home-component">
            Suscríbete y recibe información de los productos, eventos y <br />{" "}
            ofertas que tendremos listos para ti.
          </div>
        </div>
        <div className="right-content-suscribe-home-component">
          <div className="internal-right-content-suscribe-home-component">
            <div className="label-internal-right-content-suscribe-home-component">
              Tu correo electrónico
            </div>
            <input
              type="text"
              placeholder="Escribe tu correo electrónico"
              className="input-internal-right-content-suscribe-home-component"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="politicas-internal-right-content-suscribe-home-component">
              <input
                type="checkbox"
                checked={act1}
                onChange={(e) => setAct1(e.target.checked)}
              />
              <label htmlFor="">
                Acepto política de datos, términos y condiciones.
              </label>
            </div>
            <div className="politicas-internal-right-content-suscribe-home-component">
              <div>
                <input
                  type="checkbox"
                  checked={act2}
                  onChange={(e) => setAct2(e.target.checked)}
                />
              </div>
              <div>
                <label htmlFor="">
                  Autorizo la transferencia y transmisión de mis datos para las
                  finalidades de publicidad a las compañías de su grupo.
                </label>
              </div>
            </div>
            <div className="text-politicas-internal-right-content-suscribe-home-component">
              Estamos comprometidos con tu privacidad, por ello, en Hyundai
              Colombia S.A.S. junto con nuestros aliados oficiales te aseguramos
              que utilizaremos la información que nos proporcionas para
              comunicarnos contigo acerca de nuestros productos, servicios,
              ofertas, invitaciones, encuestas, entre otras finalidades y
              contenidos relevantes para ti. Puedes darte de baja de estas
              comunicaciones en cualquier momento. Para obtener más información
              consulta nuestra
              <br />
              <a href="#">Política de privacidad.</a>
            </div>
            <div
              className={`btn-internal-right-content-suscribe-home-component ${
                isButtonDisabled ? "disabled" : ""
              }`}
              onClick={handleSubscribe}
              style={{
                pointerEvents: isButtonDisabled ? "none" : "auto",
                opacity: isButtonDisabled ? 0.5 : 1,
              }}
            >
              Suscribirme
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuscribeComponent;
