import { forwardRef } from "react";

/*eslint-disable */

declare const window: any;

interface DiseñoVehiculosComponentProps {
  diseño: any;
}

// Uso de forwardRef para aceptar el ref desde el componente padre
const DiseñoVehiculosComponent = forwardRef<HTMLDivElement, DiseñoVehiculosComponentProps>(
  ({ diseño }, ref) => {

  return (
    <div className="diseño-vehiculos-detail-component" ref={ref}>
      <div className="title-diseño-vehiculos-detail-component">
        {diseño.title}
      </div>
      {diseño.contenido.map((item: any, index: number) => (
        <div className={item.size === "small" ? `small-diseño-vehiculos-detail-component ${item.class}` : `large-diseño-vehiculos-detail-component ${item.class}`}>
          <div className={item.size === "small" ? `asset-small-diseño-vehiculos-detail-component` : `asset-large-diseño-vehiculos-detail-component`}>
            {
              item.type === "video" ? 
              <video
                className="active-video-banner-home-component"
                src={item.src} // La URL local del video
                autoPlay
                muted
                loop
                playsInline
              /> : <img src={item.src} alt="" />
            }
          </div>
          <div className={item.size === "small" ? `text-small-diseño-vehiculos-detail-component` : `text-large-diseño-vehiculos-detail-component`}>
            <div className={item.size === "small" ? `title-text-small-diseño-vehiculos-detail-component` : `title-text-large-diseño-vehiculos-detail-component`}>
              {item.title}
            </div>
            <div className={item.size === "small" ? `description-text-small-diseño-vehiculos-detail-component` : `description-text-large-diseño-vehiculos-detail-component`}>
              {item.detalle}
            </div>
          </div>
        </div>
      ))}
    </div>
    );
  }
);
export default DiseñoVehiculosComponent;
