import { forwardRef, useRef, useState } from "react";
import combustible from "../../assets/03_Producto/Motor.svg";
import motor from "../../assets/03_Producto/Transmision.svg";
import garantia from "../../assets/03_Producto/Garantia.svg";
import { Link } from "react-router-dom";

/*eslint-disable */

declare const window: any;

interface VersionesVehiculosComponentProps {
  versiones: any;
}

const VersionesVehiculosComponent = forwardRef<HTMLDivElement, 
  VersionesVehiculosComponentProps
>  (({ versiones }, ref) => {
  const scrollContainerRef: any = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0); // Estado para el índice actual del scroll

  // Función para mover el scroll hacia la izquierda (prev)
  const handlePrev = () => {
    scrollContainerRef.current.scrollBy({
      left: -394, // Desplaza hacia la izquierda
      behavior: "smooth", // Scroll suave
    });
  };

  // Función para mover el scroll hacia la derecha (next)
  const handleNext = () => {
    scrollContainerRef.current.scrollBy({
      left: 394, // Desplaza hacia la derecha
      behavior: "smooth",
    });
  };

  return (
    <div className="versiones-vehiculos-detail-component" ref={ref}>
      <div className="title-versiones-vehiculos-detail-component">
        Encuentra el Hyundai que mejor se adapte a ti
      </div>
      <div
        className="btn-carrousel-versiones-vehiculos-detail-component prev"
        onClick={handlePrev}
      >
        <img src="../assets/01_Home/Icon_Left.svg" alt="Prev" />
      </div>
      <div
        className="carrousel-versiones-vehiculos-detail-component"
        ref={scrollContainerRef}
      >
        {versiones.map((item: any, index: any) => (
          <div
            key={index}
            className={`item-versiones-vehiculos-detail-component`}
          >
            {/* Contenido de cada item */}
            <div className={`title-item-versiones-vehiculos-detail-component`}>
              {item.title}
            </div>
            <div
              className={`asset-item-versiones-vehiculos-detail-component`}
              style={{ backgroundImage: `URL(${item.src})` }}
            ></div>
            <div
              className={`subtitle-precio-item-versiones-vehiculos-detail-component`}
            >
              Disponible desde
            </div>
            <div className={`precio-item-versiones-vehiculos-detail-component`}>
              {item.precio}
            </div>
            <div className="separador-item-versiones-vehiculos-detail-component"></div>
            <div
              className={`colores-item-versiones-vehiculos-detail-component`}
            >
              <div
                className={`color-item-versiones-vehiculos-detail-component`}
                style={{ backgroundColor: "#222222" }}
              ></div>
              <div
                className={`color-item-versiones-vehiculos-detail-component`}
                style={{ backgroundColor: "#6A695D" }}
              ></div>
              <div
                className={`color-item-versiones-vehiculos-detail-component`}
                style={{ backgroundColor: "#AAAAAA" }}
              ></div>
              <div
                className={`color-item-versiones-vehiculos-detail-component`}
                style={{ backgroundColor: "#600101" }}
              ></div>
              <div
                className={`color-item-versiones-vehiculos-detail-component`}
                style={{ backgroundColor: "#697E69" }}
              ></div>
              <div
                className={`color-item-versiones-vehiculos-detail-component`}
                style={{ backgroundColor: "#D3D3D3" }}
              ></div>
              <div
                className={`color-item-versiones-vehiculos-detail-component`}
                style={{ backgroundColor: "#AFD653" }}
              ></div>
              <div
                className={`color-item-versiones-vehiculos-detail-component`}
                style={{ backgroundColor: "#292E45" }}
              ></div>
              <div
                className={`color-item-versiones-vehiculos-detail-component`}
                style={{ backgroundColor: "#E1E1E1" }}
              ></div>
            </div>
            <div
              className={`opciones-item-versiones-vehiculos-detail-component`}
            >
              <img src={motor} alt="" />
              <div>{item.motor}</div>
            </div>
            <div
              className={`opciones-item-versiones-vehiculos-detail-component`}
            >
              <img src={combustible} alt="" />
              <div>{item.transmision}</div>
            </div>
            <div
              className={`opciones-item-versiones-vehiculos-detail-component`}
            >
              <img src={garantia} alt="" />
              <div>{item.garantia}</div>
            </div>
            <Link
              to={item.ver}
              className="mas-item-versiones-vehiculos-detail-component"
            >
              Saber más
            </Link>
            <Link
              to={item.cotiza}
              className="cotiza-item-versiones-vehiculos-detail-component"
            >
              Cotiza
            </Link>
          </div>
        ))}
      </div>
      <div
        className="btn-carrousel-versiones-vehiculos-detail-component next"
        onClick={handleNext}
      >
        <img src="../assets/01_Home/Icon_Right.svg" alt="Next" />
      </div>
    </div>
  );
}
)

export default VersionesVehiculosComponent;
