import { forwardRef, useEffect } from "react";
import fjGallery from "flickr-justified-gallery";

/*eslint-disable */

declare const window: any;

interface GaleriaVehiculosComponentProps {
  selectedGalery: any;
  setSelectedGalery: any;
  galeria: any;
}

const GaleriaVehiculosComponent = forwardRef<
  HTMLDivElement,
  GaleriaVehiculosComponentProps
>(({ selectedGalery, setSelectedGalery, galeria }, ref) => {
  useEffect(() => {
    fjGallery(document.querySelectorAll(".gallery"), {
      itemSelector: ".gallery__item",
      rowHeight: 180,
      lastRow: "start",
      gutter: 2,
      rowHeightTolerance: 0.1,
      calculateItemsHeight: false,
    });
  }, []);

  return (
    <div className="galeria-vehiculos-detail-component" ref={ref}>
      <div className="title-galeria-vehiculos-detail-component">Galería</div>
      <div className="colmena-galeria-vehiculos-detail-component">
        {galeria.map((item: any, index: any) => (
          <div
            className={`item-colmena-galeria-vehiculos-detail-component ${
              index === selectedGalery ? "active" : ""
            }`}
            onClick={() =>
              setSelectedGalery(index === selectedGalery ? null : index)
            }
          >
            <img src={item} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
});

export default GaleriaVehiculosComponent;
