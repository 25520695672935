import React from "react";
import { Link } from "react-router-dom";

/*eslint-disable */

declare const window: any;

interface ReservaVehiculosComponentProps {
  vehiculo: any
}

const ReservaVehiculosComponent: React.FC<
  ReservaVehiculosComponentProps
> = ({vehiculo}) => {
  return (
    <div className="reserva-vehiculos-detail-component">
      <div className="vehiculo-reserva-vehiculos-detail-component">
        <img src={vehiculo.src} alt="" />
      </div>
      <div className="text-reserva-vehiculos-detail-component">
        <div className="title-text-reserva-vehiculos-detail-component">Reserva este modelo</div>
        <div className="subtitle-text-reserva-vehiculos-detail-component">Apártalo desde</div>
        <div className="precio-text-reserva-vehiculos-detail-component">$ {vehiculo.precio} cop</div>
        <Link to={vehiculo.reserva} className="btn-text-reserva-vehiculos-detail-component">Reservar en línea</Link>
      </div>
    </div>
  );
};

export default ReservaVehiculosComponent;
