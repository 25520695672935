import React from "react";

/*eslint-disable */

declare const window: any;

interface CategoriesComponentProps {
    setSelectedCategories: any,
    selectedCategories: any
}

const CategoriesComponent: React.FC<CategoriesComponentProps> = ({setSelectedCategories, selectedCategories}) => {
  return <div className="categories-vehiculos-component">
    <div className={`categoria-vehiculos-component ${selectedCategories === "Todos" ? "active" : ""}`} onClick={() => setSelectedCategories("Todos")}>Todos</div>
    <div className={`categoria-vehiculos-component ${selectedCategories === "Híbridos" ? "active" : ""}`} onClick={() => setSelectedCategories("Híbridos")}>Híbridos</div>
    <div className={`categoria-vehiculos-component ${selectedCategories === "Eléctricos" ? "active" : ""}`} onClick={() => setSelectedCategories("Eléctricos")}>Eléctricos</div>
    <div className={`categoria-vehiculos-component ${selectedCategories === "Automóviles" ? "active" : ""}`} onClick={() => setSelectedCategories("Automóviles")}>Automóviles</div>
    <div className={`categoria-vehiculos-component ${selectedCategories === "SUV" ? "active" : ""}`} onClick={() => setSelectedCategories("SUV")}>SUV</div>
    <div className={`categoria-vehiculos-component ${selectedCategories === "Vanes" ? "active" : ""}`} onClick={() => setSelectedCategories("Vanes")}>Vanes</div>
    <div className={`categoria-vehiculos-component ${selectedCategories === "Comerciales" ? "active" : ""}`} onClick={() => setSelectedCategories("Comerciales")}>Comerciales</div>
  </div>;
};

export default CategoriesComponent;
