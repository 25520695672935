import { forwardRef, useState } from "react";

/*eslint-disable */

declare const window: any;

interface TecnologiaVehiculosComponentProps {
  tecnologias: any;
}

const TecnologiaVehiculosComponent = forwardRef<HTMLDivElement, 
  TecnologiaVehiculosComponentProps
>  (({ tecnologias }, ref) => {
  const [currentIndex, setCurrentIndex] = useState(0); // Inicialmente se muestra la imagen en el centro

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : tecnologias.length - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < tecnologias.length - 1 ? prevIndex + 1 : 0
    );
  };

  console.log(currentIndex);

  return (
    <div className="tecnologia-vehiculos-detail-component" ref={ref}>
      <div className="title-tecnologia-vehiculos-detail-component">
        Tecnología que asiste tu viaje
      </div>
      <div
        className="carrousel-tecnologia-vehiculos-detail-component"
        style={{
          transform: `translateX(-${currentIndex * 100}%)`,
        }}
      >
        {tecnologias.map((item: any, index: any) => (
          <div className={`item-tecnologia-vehiculos-detail-component`}>
            <img src={item.src} alt="" />
          </div>
        ))}
      </div>
      <div className="info-tecnologia-vehiculos-detail-component">
        {tecnologias.map((item: any, index: number) => (
          <div
            className={`item-info-tecnologia-vehiculos-detail-component ${
              index === currentIndex ? "active" : ""
            }`}
            onClick={() => setCurrentIndex(index)}
          >
            <div className="title-info-tecnologia-vehiculos-detail-component">
              {item.title}
            </div>
            <div className="descripcion-info-tecnologia-vehiculos-detail-component">
              {item.descripcion}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
)

export default TecnologiaVehiculosComponent;
