import React from 'react';
import icon_portafolio from "../../assets/01_Home/Icon_Portfolio.png";

/*eslint-disable */

const portafolios: any = [
    { name: "KONA", precio: "79.000.000", precio_especial: "69.000.000", large: true, asset: './assets/portafolios/KONA_Prueba.webp', asset_atr: './assets/00_Sellos/KONA_HEV.png' },
    { name: "TUCSON", precio: "79.000.000", precio_especial: null, large: true, asset: './assets/portafolios/Tucson-NX4.webp', asset_atr: './assets/00_Sellos/TUCSON.png' },
    { name: "IONIQ 5", precio: "79.000.000", precio_especial: null, large: false, asset: './assets/portafolios/Ioniq5.webp', asset_atr: './assets/00_Sellos/IONIQ5.png' },
    { name: "PALISADE", precio: "79.000.000", precio_especial: null, large: false, asset: './assets/portafolios/KONA_Prueba.webp', asset_atr: './assets/00_Sellos/Palisade.png' },
    { name: "VENUE", precio: "89.000.000", precio_especial: "69.000.000", large: true, asset: './assets/portafolios/Venue.webp', asset_atr: './assets/00_Sellos/VENUE.png' },
    { name: "CRETA", precio: "89.000.000", precio_especial: "69.000.000", large: true, asset: './assets/portafolios/Creta.webp', asset_atr: './assets/00_Sellos/CRETA.png' },
    { name: "HB20 Sedán", precio: "69.000.000", precio_especial: "59.000.00", large: false, asset: './assets/portafolios/HB20S.webp', asset_atr: './assets/00_Sellos/HB20_Sedan.png' },
    { name: "HB20 Hatchback", precio: "69.000.000", precio_especial: null, large: false, asset: './assets/portafolios/HB20.webp', asset_atr: './assets/00_Sellos/HB20_Hatchback.png' },
    { name: "STARIA Pasajeros", precio: "69.000.000", precio_especial: null, large: false, asset: './assets/portafolios/STARIA.webp', asset_atr: './assets/00_Sellos/Staria_Pasajeros.png' },
    { name: "GRAND METRO Taxi", precio: "69.000.000", precio_especial: "69.000.000", large: false, asset: './assets/portafolios/Metro-ACCENT.webp', asset_atr: './assets/00_Sellos/Metro_Taxi.png' }
]

const PortafolioComponent: React.FC = () => {
    return (
        <div className="portafolio-home-component">
            <div className='title-portafolio-home-component'>
                Nuestro portafolio
            </div>
            <div className='cards-portafolio-home-component'>
                {portafolios.map((item: any, index: number) =>
                (item.large ? (<div className='large-individual-card-portafolio-home-component' style={{ backgroundImage: `URL(${item.asset})` }}>
                    <div className='text-large-individual-card-portafolio-home-component'>
                        <h1>{item.name}</h1>
                        <p>Precio desde: ${item.precio} cop</p>
                        {item.precio_especial ? <p>Precio especial desde: ${item.precio_especial} cop</p> : null}
                    </div>
                    <div className='accion-large-individual-card-portafolio-home-component'>
                        <img src={icon_portafolio} alt="" />
                        <div className='btn-vive-la-exp-accion-large-individual-card-portafolio-home-component'>
                            Vive la experiencia
                        </div>
                        <div className='btn-opciones-accion-large-individual-card-portafolio-home-component'>
                            <div className='btn-compara-accion-large-individual-card-portafolio-home-component'>Compara este vehículo</div>
                            <div className='btn-reserva-accion-large-individual-card-portafolio-home-component'>Reserva en línea</div>
                        </div>
                        <div className='condiciones-large-individual-card-portafolio-home-component'>
                            <a href="">Consulta los términos y condiciones aquí.</a>
                        </div>
                    </div>
                </div>)
                    : (<div className='small-individual-card-portafolio-home-component' style={{ backgroundImage: `URL(${item.asset})` }}>
                        <div className='text-small-individual-card-portafolio-home-component'>
                        <h1>{item.name}</h1>
                        <p>Precio desde: ${item.precio} cop</p>
                        {item.precio_especial ? <p>Precio especial desde: ${item.precio_especial} cop</p> : null}
                    </div>
                    <div className='accion-small-individual-card-portafolio-home-component'>
                        <img src={item.asset_atr} alt="" />
                        <div className='btn-vive-la-exp-accion-small-individual-card-portafolio-home-component'>
                            Vive la experiencia
                        </div>
                        <div className='btn-opciones-accion-small-individual-card-portafolio-home-component'>
                            <div className='btn-compara-accion-small-individual-card-portafolio-home-component'>Compara este vehículo</div>
                            <div className='btn-reserva-accion-small-individual-card-portafolio-home-component'>Reserva en línea</div>
                        </div>
                        <div className='condiciones-small-individual-card-portafolio-home-component'>
                            <a href="">Consulta los términos y condiciones aquí.</a>
                        </div>
                    </div>
                    </div>))
                )}
            </div>
        </div>
    );
}

export default PortafolioComponent;