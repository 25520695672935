import React from 'react';
import blog_ver_mas from "../../assets/01_Home/Blog_CTA-Thumbnail.webp";

/*eslint-disable */

const blogs: any = [
    { name: "Hyundai y la huella de carbono", category: "Noticias", asset: "./assets/01_Home/Blog_1.webp" },
    { name: "Nombre de un testimonio en dos líneas", category: "Recomendaciones", asset: "./assets/01_Home/Blog_2.webp" },
    { name: "Las ventajas de tener tu propio hyundai eléctrico en ...", category: "Comunidad Hyundai", asset: "./assets/01_Home/Blog_3.webp" }
]

const BlogComponent: React.FC = () => {

    return (
        <div className="blog-home-component">
            <div className='title-blog-home-component'>
                Novedades y comunidad Hyundai
            </div>
            <div className='items-blog-home-component'>
                {blogs.map((item: any, index: number) =>
                    <div className='item-blog-home-component'>
                        <img src={item.asset} alt="" />
                        <div className='descripcion-item-blog-home-component'>
                            <div className='category-descripcion-item-blog-home-component'>{item.category}</div>
                            <div className='name-descripcion-item-blog-home-component'>{item.name}</div>
                        </div>
                        <div className='mascara-item-blog-home-component'></div>
                    </div>
                )}
                <div className='item-vermas-blog-home-component'>
                    <img src={blog_ver_mas} alt="" />
                    <div className='mascara-item-blog-home-component'>
                        Ver más noticias <br />sobre Hyundai
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogComponent;