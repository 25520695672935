import React from "react";
import { Link } from "react-router-dom";

/*eslint-disable */

declare const window: any;

interface SimuladorComponentProps {
  modelo: any;
  setModelo: any;
  modelos: any;
  setModelos: any;
  version: any;
  setVersion: any;
  politica_1: any;
  setPolitica_1: any;
  politica_2: any;
  setPolitica_2: any;
  setCurrentIndex: any
}

const SimuladorComponent: React.FC<SimuladorComponentProps> = ({
  modelo,
  setModelo,
  modelos,
  setModelos,
  version,
  setVersion,
  politica_1,
  setPolitica_1,
  politica_2,
  setPolitica_2,
  setCurrentIndex
}) => {
  console.log(modelo);
  console.log(version);

  return (
      <div className="content-simulador-component" >
        <h1>Simulador de cuotas</h1>
        <div className="step-content-simulador-component">
          <div className="step_1 active" onClick={() => setCurrentIndex(0)}>
            <span>1</span>
          </div>
          <div className="line"></div>
          <div className="step_2" onClick={() => {modelo !== "" && politica_1 && politica_2 ? setCurrentIndex(1) : null}}>
            <span>2</span>
          </div>
        </div>
        <div className="subtitle-content-simulador-component">
          Selecciona un vehículo para calcular los pagos.
        </div>
        <div className="internal-content-simulador-component">
          <div className="vehiculo-internal-content-simulador-component">
            <div
              className={`asset-vehiculo-internal-content-simulador-component ${
                modelo !== "" ? "active" : ""
              }`}
            >
              {modelo !== "" ? (
                <img
                  src={modelos.find((item: any) => item.modelo === modelo)?.src}
                  alt=""
                />
              ) : null}
            </div>
            <div className="politicas-vehiculo-internal-content-simulador-component">
              *Los valores y precios publicados son de referencia y pueden
              variar sin previo aviso.
            </div>
            <div className="politicas-vehiculo-internal-content-simulador-component">
              *Las imágenes son ilustrativas, algunas características de los
              vehículos de las imágenes pueden variar.
            </div>
          </div>

          <div className="seleccion-internal-content-simulador-component">
            <div className="modelo-seleccion-internal-content-simulador-component">
              <label htmlFor="">Modelo</label>
              <select
                name=""
                id=""
                value={modelo}
                onChange={(e) => {
                  setModelo(e.target.value),
                    setVersion(
                      modelos.find(
                        (item: any) => item.modelo === e.target.value
                      )?.versiones[0]
                    );
                }}
              >
                {modelo === "" ? <option value=""></option> : null}
                {modelos.map((item: any, index: number) => (
                  <option value={item.modelo}>{item.title}</option>
                ))}
              </select>
            </div>
            <div
              className={`version-seleccion-internal-content-simulador-component ${
                modelo === "" ? "oculto" : ""
              }`}
            >
              <label htmlFor="">Versión</label>
              <select
                name=""
                id=""
                value={version}
                onChange={(e) => setVersion(e.target.value)}
              >
                {modelos
                  .find((item: any) => item.modelo === modelo)
                  ?.versiones.map((item: any, index: number) => (
                    <option value="item">{item}</option>
                  ))}
              </select>
            </div>
            <div className="politica-seleccion-internal-content-simulador-component">
              <div>
                <input
                  type="checkbox"
                  name="politicas-1"
                  id=""
                  checked={politica_1}
                  onChange={(e) => setPolitica_1(e.target.checked)}
                />
              </div>
              <div>
                <label htmlFor="">
                  Acepto política de datos, términos y condiciones.
                </label>
              </div>
            </div>
            <div className="politica-seleccion-internal-content-simulador-component">
              <div>
                <input
                  type="checkbox"
                  name="politicas-1"
                  id=""
                  checked={politica_2}
                  onChange={(e) => setPolitica_2(e.target.checked)}
                />
              </div>
              <div>
                <label htmlFor="">
                  Autorizo la transferencia y transmisión de mis datos para las
                  finalidades de publicidad a las compañías de su grupo.
                </label>
              </div>
            </div>
            <div className="terminos-seleccion-internal-content-simulador-component">
              Estamos comprometidos con tu privacidad, por ello, en Hyundai
              Colombia S.A.S. junto con nuestros aliados oficiales te aseguramos
              que utilizaremos la información que nos proporcionas para
              comunicarnos contigo acerca de nuestros productos, servicios,
              ofertas, invitaciones, encuestas, entre otras finalidades y
              contenidos relevantes para ti. Puedes darte de baja de estas
              comunicaciones en cualquier momento. Para obtener más información
              consulta nuestra <br />
              <Link to={"#"}>Política de privacidad.</Link>
            </div>
          </div>
        </div>
        <div
          className={`btn-next-content-simulador-component ${
            modelo !== "" && politica_1 && politica_2
              ? "active"
              : ""
          }`}
          onClick={() => setCurrentIndex(1)}
        >
          Siguiente
        </div>
      </div>
  );
};

export default SimuladorComponent;
