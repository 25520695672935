import { forwardRef, useState } from "react";

/*eslint-disable */

declare const window: any;

interface ModelosVehiculosComponentProps {
  modelos: any
}

const ModelosVehiculosComponent = forwardRef<HTMLDivElement, ModelosVehiculosComponentProps> ((
  {modelos},
  ref
) => {
  const [selectedVersion, setSelectedVersion] = useState(0);
  const [selectedVersionColor, setSelectedVersioncolor] = useState(0);
  return (
    <div className="modelos-vehiculos-detail-component" ref={ref}>
      <div className="title-modelos-vehiculos-detail-component">
        Más de una posibilidad en tu Hyundai
      </div>
      <div className="version-modelos-vehiculos-detail-component">
        {modelos.map((model: any, index: number) => (
          <div
            className={`item-version-modelos-vehiculos-detail-component ${
              model.name === modelos[selectedVersion].name ? "active" : ""
            }`}
            onClick={() => setSelectedVersion(index)}
          >
            {model.name}
          </div>
        ))}
      </div>
      <div className="name-version-modelos-vehiculos-detail-component">
        Versión {modelos[selectedVersion].name.toUpperCase()}
      </div>
      <div className="precio-version-modelos-vehiculos-detail-component">
        Disponible desde $ {modelos[selectedVersion].precio}
      </div>
      <div className="asset-color-version-modelos-vehiculos-detail-component">
        <div className="asset-version-modelos-vehiculos-detail-component">
          <img src={modelos[selectedVersion].colores[selectedVersionColor].src} alt="" />
        </div>
        <div className="control-color-version-modelos-vehiculos-detail-component">
          <div className="colores-control-color-version-modelos-vehiculos-detail-component">
            {
              modelos[selectedVersion].colores.map((color: any, e: number) => 
                <div className={modelos[selectedVersion].colores[selectedVersionColor].color === color.color ? "active" : ""} style={{backgroundColor: color.codigo, border: `2px solid ${color.codigo}`}} onClick={() => setSelectedVersioncolor(e)}></div>
              )
            }
          </div>
          <div className="title-control-color-version-modelos-vehiculos-detail-component">{modelos[selectedVersion].colores[selectedVersionColor].color}</div>
        </div>
      </div>
      <div className="caracteristica-version-modelos-vehiculos-detail-component">
        {modelos[selectedVersion].caracteristicas.map(
          (item: any, index: number) => (
            <div className="item-caracteristica-version-modelos-vehiculos-detail-component">
              <div className="title-item-caracteristica-version-modelos-vehiculos-detail-component">
                {item.title}
              </div>
              <div className="description-item-caracteristica-version-modelos-vehiculos-detail-component">
                {item.detalle}
              </div>
            </div>
          )
        )}
      </div>
      <div className="compara-version-modelos-vehiculos-detail-component">
        Compara las versiones
      </div>
      {/* <div className="small-diseño-vehiculos-detail-component normal">
        <div className="asset-small-diseño-vehiculos-detail-component">
          <video
            className="active-video-banner-home-component"
            src="../assets/01_Home/Ioniq 5Optimized.mp4" // La URL local del video
            autoPlay
            muted
            loop
            playsInline
          />
        </div>
        <div className="text-small-diseño-vehiculos-detail-component">
          <div className="title-text-small-diseño-vehiculos-detail-component">
            Faros delanteros 100% LED tipo MFR.
          </div>
          <div className="description-text-small-diseño-vehiculos-detail-component">
            La iluminación LED ofrece claridad y visibilidad superior a las
            luces halógenas tradicionales, permitiendo ver más lejos y con mayor
            claridad durante la noche o en condiciones de baja visibilidad.
          </div>
        </div>
      </div> */}
    </div>
    );
  }
);

export default ModelosVehiculosComponent;
