import React, { useState, useEffect } from "react";
import axios from "axios";
import logo_hyundai from "../assets/01_Home/Icon_Hyundai-White.svg";
import Icon_Facebook from "../assets/01_Home/Icon_Facebook.svg";
import Icon_Instagram from "../assets/01_Home/Icon_Instagram.svg";
import Icon_Twitter from "../assets/01_Home/Icon_Twitter-X.svg";
import Icon_youtube from "../assets/01_Home/Icon_youtube.svg";

/*eslint-disable */

const FooterLayouts: React.FC = () => {
  const [selectSection, setSelectSection] = useState("");
  const [email, setEmail] = useState("");
  const [act1, setAct1] = useState(false);
  const [act2, setAct2] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    // Deshabilitar el botón si no están ambos checkboxes marcados
    setIsButtonDisabled(!(act1 && act2));
  }, [act1, act2]);

  const handleSubscribe = async () => {
    if (!email) {
      alert("Por favor, ingresa tu correo electrónico.");
      return;
    }

    try {
      const response = await axios.post(
        "https://8d6cxglsnh.execute-api.us-east-1.amazonaws.com/api/suscribe",
        {
          email,
          act1,
          act2,
        }
      );
      alert("Suscripción exitosa!");
      // Limpiar los campos después de la suscripción exitosa
      setEmail("");
      setAct1(false);
      setAct2(false);
    } catch (error) {
      console.error("Error al suscribirse:", error);
      alert(
        "Hubo un error al intentar suscribirte. Por favor, inténtalo de nuevo."
      );
    }
  };

  return (
    <div className="footer-layout">
      <div className="logo-footer-layout">
        <img src={logo_hyundai} alt="Hyundai Logo" />
      </div>
      <div className="content-footer-layout">
        <div
          className={`left-content-footer-layout ${
            selectSection === "left" ? "active" : ""
          }`}
        >
          <div
            className="legal-txt-left-content-footer-layout"
            onClick={() =>
              setSelectSection(selectSection !== "left" ? "left" : "")
            }
          >
            Legales
          </div>
          <a className="legal-page-left-content-footer-layout">
            Términos y condiciones
          </a>
          <a className="legal-page-left-content-footer-layout">
            Políticas de la página
          </a>
          <a className="legal-page-left-content-footer-layout">
            Avisos de privacidad
          </a>
          <a className="legal-page-left-content-footer-layout">
            Política de protección de datos
          </a>
          <a className="legal-page-left-content-footer-layout">
            Política de calidad
          </a>
          <a className="legal-page-left-content-footer-layout">
            Política de Cookies
          </a>
        </div>
        <div
          className={`center-content-footer-layout ${
            selectSection === "center" ? "active" : ""
          }`}
        >
          <div
            className="legal-txt-center-content-footer-layout"
            onClick={() =>
              setSelectSection(selectSection !== "center" ? "center" : "")
            }
          >
            Descubre Hyundai
          </div>
          <a className="legal-page-center-content-footer-layout">
            Sobre nosotros
          </a>
          <a className="legal-page-center-content-footer-layout">
            Últimas noticias
          </a>
          <a className="ics-page-center-content-footer-layout">
            Hyundai Interactive Car Studio
          </a>
          <a className="hc-page-center-content-footer-layout">
            Hyundai Colombia
          </a>
          <div className="socialmedia-center-content-footer-layout">
            <a href="#">
              <img src={Icon_youtube} alt="YouTube" />
            </a>
            <a href="#">
              <img src={Icon_Facebook} alt="Facebook" />
            </a>
            <a href="#">
              <img src={Icon_Twitter} alt="Twitter" />
            </a>
            <a href="#">
              <img src={Icon_Instagram} alt="Instagram" />
            </a>
          </div>
        </div>
        <div
          className={`right-content-footer-layout ${
            selectSection === "right" ? "active" : ""
          }`}
        >
          <div
            className="legal-txt-right-content-footer-layout"
            onClick={() =>
              setSelectSection(selectSection !== "right" ? "right" : "")
            }
          >
            ¡Suscríbete y recibe las últimas novedades!
          </div>
          <div className="label-right-content-footer-layout">
            Tu correo electrónico
          </div>
          <div className="accion-right-content-footer-layout">
            <input
              type="text"
              placeholder="Escribe tu correo electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div
              className={`subscribe-button ${
                isButtonDisabled ? "disabled" : ""
              }`}
              onClick={handleSubscribe}
              style={{
                pointerEvents: isButtonDisabled ? "none" : "auto",
                opacity: isButtonDisabled ? 0.5 : 1,
              }}
            >
              Suscribirme
            </div>
          </div>
          <div className="politicas-internal-accion-right-content-footer-layout">
            <input
              type="checkbox"
              checked={act1}
              onChange={(e) => setAct1(e.target.checked)}
            />
            <label htmlFor="">
              Acepto política de datos, términos y condiciones.
            </label>
          </div>
          <div className="politicas-internal-accion-right-content-footer-layout">
            <div>
              <input
                type="checkbox"
                checked={act2}
                onChange={(e) => setAct2(e.target.checked)}
              />
            </div>
            <label htmlFor="">
              Autorizo la transferencia y transmisión de mis datos <br /> para
              las finalidades de publicidad a las compañías de su grupo.
            </label>
          </div>
          <div className="text-politicas-internal-accion-right-content-footer-layout">
            <a href="#">Política de privacidad.</a>
          </div>
        </div>
      </div>
      <div className="derechos-footer-layout">
        2024 Hyundai Motor Company Colombia. Todos los derechos reservados
      </div>
    </div>
  );
};

export default FooterLayouts;
