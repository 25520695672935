import React, { useEffect, useState } from "react";

/*eslint-disable */

declare const window: any;

interface GaleriaVehiculosComponentProps {
  selectedGalery: any;
  setSelectedGalery: any;
  galeria: any;
}

const GaleriaModalVehiculosComponent: React.FC<
  GaleriaVehiculosComponentProps
> = ({ selectedGalery, setSelectedGalery, galeria }) => {
  const [currentIndex, setCurrentIndex] = useState(4); // Inicialmente se muestra la imagen en el centro

  useEffect(() => {
    setCurrentIndex(selectedGalery);
  }, [selectedGalery]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : galeria.length - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < galeria.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <div
      className={`galeria-modal-vehiculos-detail-component ${
        selectedGalery !== null ? "active" : ""
      }`}
    >
      <div className="slider-galeria-vehiculos-detail-component">
        <div
          className="slider-wrapper"
          style={{
            transform:
            currentIndex === 0
            ? `translateX(${25}%)`
            : `translateX(-${(currentIndex * 25) === 25 ? currentIndex * 25 : (currentIndex * 25) === 50 ? currentIndex * 25 + 25: (currentIndex * 50 + 25) - 50}%)`,
          }}
        >
          {galeria.map((src: any, index: any) => (
            <div
              key={index}
              className="item-slider-galeria-modal-vehiculos-detail-component"
            >
              <img src={src} alt={`Imagen ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-slider-galeria-vehiculos-detail-component">
        <div className="close-bottom-slider-galeria-vehiculos-detail-component" onClick={() => setSelectedGalery(null)}>
          <img src="../assets/01_Home/Close.svg" alt="" />
        </div>
        <div className="control-bottom-slider-galeria-vehiculos-detail-component">
          <div onClick={handlePrev} className="slider-button prev">
            <img src="../assets/01_Home/Icon_Left.svg" alt="" />
          </div>
          <div onClick={handleNext} className="slider-button next">
            <img src="../assets/01_Home/Icon_Right.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GaleriaModalVehiculosComponent;
