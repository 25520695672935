import React from "react";

/*eslint-disable */

declare const window: any;

interface ComunidadVehiculosComponentProps {
  comunidad: any;
}

const ComunidadVehiculosComponent: React.FC<ComunidadVehiculosComponentProps> = ({
  comunidad,
}) => {

  return (
    <div className="comunidad-vehiculos-detail-component">
      <div className="title-comunidad-vehiculos-detail-component">Comunidad Hyundai</div>
      <div className="colmena-comunidad-vehiculos-detail-component">
        {comunidad.map((item: any, index: any) => (
          <div
            className={`item-colmena-comunidad-vehiculos-detail-component`}
          >
            <img src={item} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ComunidadVehiculosComponent;
