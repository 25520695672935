import React from 'react';

/*eslint-disable */

const pages: any = [
    { name: "Agenda una Cita Taller", link: "#", large: false, asset: './assets/01_Home/Thumbnail_CitaTaller.webp' },
    { name: "Concesionarios", link: "#", large: false, asset: './assets/01_Home/Thumbnail_Concesionarios.webp' },
    { name: "Simula tu cuota", link: "#", large: false, asset: './assets/01_Home/Thumbnail_Repuestos.webp' },
    { name: "Repuestos genuinos", link: "#", large: false, asset: './assets/01_Home/Thumbnail_Simulador.webp' }
]

const OtherPageComponent: React.FC = () => {

    return (
        <div className="other-page-home-component">
            <div className="title-other-page-home-component">Más oportunidades</div>
            <div className="pages-other-page-home-component">
                {pages.map((item: any, index: number) =>
                (item.large ? (<div className='large-page-other-page-home-component' style={{ backgroundImage: `URL(${item.asset})` }}>
                    <div className='mascara-page-other-page-home-component'>
                        <div className='name-page-other-page-home-component'>{item.name}</div>
                        <a href={item.link} className='btn-page-other-page-home-component'></a>
                    </div>
                    <div className='name-page-other-page-home-component'>{item.name}</div>
                    <a href={item.link} className='btn-page-other-page-home-component'>Conocer</a>
                </div>)
                    : (<div className='small-page-other-page-home-component' style={{ backgroundImage: `URL(${item.asset})` }}>
                        <div className='mascara-page-other-page-home-component'>
                            <div className='name-page-other-page-home-component'>{item.name}</div>
                            <a href={item.link} className='btn-page-other-page-home-component'>Conocer</a>
                        </div>
                    </div>)
                ))}
            </div>
        </div>
    );
}

export default OtherPageComponent;