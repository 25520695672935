import React, { useState } from "react";
import MenuLayouts from "../layouts/menu.layout";
import FooterLayouts from "../layouts/footer.layout";
import BannerComponent from "../components/vehiculos/banner.component";
import CategoriesComponent from "../components/vehiculos/categories.component";
import ContenidoComponent from "../components/vehiculos/contenido.component";

const VehiculosPage: React.FC = () => {
  const [selectedCategories, setSelectedCategories] = useState("Todos");
  return (
    <>
      <MenuLayouts className="home-page" active="vehiculo" />
      <div className="home-page">
        <div className="home-page-mascara"></div>
        <BannerComponent />
        <CategoriesComponent
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
        />
        <ContenidoComponent
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
        />
      </div>
      <FooterLayouts />
    </>
  );
};

export default VehiculosPage;
