import React, { useState } from "react";

interface DatosComponentProps {
  modelo: any;
  modelos: any;
  version: any;
  setCurrentIndex: any;
  cuotaInicial: any;
  setCuotaInicial: any;
  plazo: any;
  setPlazo: any;
  frecuenciaPago: any;
  setFrecuenciaPago: any;
  diaPago: any;
  setDiaPago: any;
  tipoPlan: any;
  setTipoPlan: any;
  cuotaExtra: any;
  setCuotaExtra: any;
}

const DatosComponent: React.FC<DatosComponentProps> = ({
  modelo,
  modelos,
  version,
  setCurrentIndex,
  cuotaInicial,
  setCuotaInicial,
  plazo,
  setPlazo,
  frecuenciaPago,
  setFrecuenciaPago,
  diaPago,
  setDiaPago,
  tipoPlan,
  setTipoPlan,
  cuotaExtra,
  setCuotaExtra,
}) => {
  const [inputValue, setInputValue]: any = useState("0");
  const [inputValueExtra, setInputValueExtra]: any = useState("0");

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
    }).format(value);
  };

  const handleCuotaInicialChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Eliminar caracteres no numéricos antes de formatear
    const rawValue = e.target.value.replace(/[^\d]/g, ""); // Mantener solo los números
    const numberValue = Number(rawValue);

    setInputValue(rawValue ? formatCurrency(numberValue) : "0");
    setCuotaInicial(numberValue);
  };

  const handleCuotaExtraChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Eliminar caracteres no numéricos antes de formatear
    const rawValue = e.target.value.replace(/[^\d]/g, ""); // Mantener solo los números
    const numberValue = Number(rawValue);

    setInputValueExtra(rawValue ? formatCurrency(numberValue) : "0");
    setCuotaExtra(numberValue);
  };

  return (
    <div className="content-simulador-component">
      <h1>Simulador de cuotas</h1>
      <div className="step-content-simulador-component">
        <div className="step_1 active" onClick={() => setCurrentIndex(0)}>
          <span>1</span>
        </div>
        <div className="line active"></div>
        <div className="step_2 active" onClick={() => setCurrentIndex(1)}>
          <span>2</span>
        </div>
      </div>
      <div className="subtitle-2-content-simulador-component">
        Llena los campos.
      </div>
      <div className="vehiculo-content-simulador-component">
        {modelo + " " + version}
      </div>
      <div className="precio-content-simulador-component">
        $ {modelos.find((item: any) => item.modelo === modelo)?.precio}
      </div>
      <div className="internal-2-content-simulador-component">
        <div className="input-internal-2-content-simulador-component">
          <label htmlFor="">Cuota inicial</label>
          <input
            type="text"
            value={inputValue}
            onChange={handleCuotaInicialChange}
          />
        </div>
        <div className="input-internal-2-content-simulador-component">
          <label htmlFor="">Plazo</label>
          <select
            name=""
            id=""
            value={plazo}
            onChange={(e) => setPlazo(e.target.value)}
          >
            <option value={24}>24</option>
            <option value={36}>36</option>
            <option value={48}>48</option>
            <option value={60}>60</option>
            <option value={72}>72</option>
          </select>
        </div>
        <div className="input-internal-2-content-simulador-component">
          <label htmlFor="">Frecuencia de pago</label>
          <select name="" id=""
            value={frecuenciaPago}
            onChange={(e) => setFrecuenciaPago(e.target.value)}>
            <option value="m">Mensual</option>
          </select>
        </div>
        <div className="input-internal-2-content-simulador-component">
          <label htmlFor="">Día de pago</label>
          <select name="" id=""
            value={diaPago}
            onChange={(e) => setDiaPago(e.target.value)}>
            <option value={2}>2</option>
            <option value={10}>10</option>
            <option value={17}>17</option>
            <option value={25}>25</option>
          </select>
        </div>
      </div>
      <div className="internal-2-content-simulador-component">
        <div className="input-internal-2-content-simulador-component">
          <label htmlFor="">Tipo de plan</label>
          <select name="" id=""
            value={tipoPlan}
            onChange={(e) => setTipoPlan(e.target.value)}>
            <option value="Tradicional">Tradicional</option>
            <option value="14Cuotas">14Cuotas</option>
            <option value="Residual">Residual</option>
          </select>
        </div>
        <div className="input-internal-2-content-simulador-component">
          <label htmlFor="">Cuota extra</label>
          <input
            type="text"
            value={inputValueExtra}
            onChange={handleCuotaExtraChange}
          />
        </div>
      </div>
      <div
        className={`btn-2-next-content-simulador-component ${
          modelo !== "" ? "active" : ""
        }`}
      >
        Simula mi cuota
      </div>
    </div>
  );
};

export default DatosComponent;
