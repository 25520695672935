import React from "react";

/*eslint-disable */

declare const window: any;

interface CotizacionVehiculosComponentProps {
  vehiculo: any;
}

const CotizacionVehiculosComponent: React.FC<
  CotizacionVehiculosComponentProps
> = ({ vehiculo }) => {
  return (
    <div className="cotizacion-vehiculos-detail-component">
      <div className="container-cotizacion-vehiculos-detail-component">
        <div className="left-container-cotizacion-vehiculos-detail-component">
          <div className="title-left-container-cotizacion-vehiculos-detail-component">
            ¡Listo para dar el primer paso!
          </div>
          <div className="descripcion-left-container-cotizacion-vehiculos-detail-component">
            Recibe una cotización detallada y agéndate a un Test drive ahorapara
            conocer todas las oportunidades que éste vehículo puede ofrecerte.
          </div>
          <div className="subtitle-left-container-cotizacion-vehiculos-detail-component">
            Estás cotizando tu Hyundai
          </div>
          <div className="vehiculos-left-container-cotizacion-vehiculos-detail-component">
            <div className="asset-vehiculos-left-container-cotizacion-vehiculos-detail-component">
              <img src={vehiculo.src} alt="" />
            </div>
            <div className="info-vehiculos-left-container-cotizacion-vehiculos-detail-component">
              <div className="title-info-vehiculos-left-container-cotizacion-vehiculos-detail-component">
                {vehiculo.title}
              </div>
              <div className="subtitle-info-vehiculos-left-container-cotizacion-vehiculos-detail-component">
                Disponible desde
              </div>
              <div className="precio-info-vehiculos-left-container-cotizacion-vehiculos-detail-component">
                $ {vehiculo.precio}
              </div>
            </div>
          </div>
          <div className="condiciones-left-container-cotizacion-vehiculos-detail-component">
            *Los valores y precios publicados son de referencia y pueden variar
            sin previo aviso.
          </div>
          <div className="condiciones-left-container-cotizacion-vehiculos-detail-component">
            *Las imágenes son ilustrativas, algunas características de los
            vehículos de las imágenes pueden variar.
          </div>
        </div>
        <div className="right-container-cotizacion-vehiculos-detail-component">
          <div className="form-right-container-cotizacion-vehiculos-detail-component">
            <div className="input-form-right-container-cotizacion-vehiculos-detail-component">
              <label htmlFor="nombre">Nombre</label>
              <input type="text" />
            </div>
            <div className="input-form-right-container-cotizacion-vehiculos-detail-component">
              <label htmlFor="nombre">Apellido</label>
              <input type="text" />
            </div>
            <div className="input-form-right-container-cotizacion-vehiculos-detail-component">
              <label htmlFor="nombre">Correo</label>
              <input type="text" />
            </div>
            <div className="input-form-right-container-cotizacion-vehiculos-detail-component">
              <label htmlFor="nombre">Celular</label>
              <input type="text" />
            </div>
            <div className="input-form-right-container-cotizacion-vehiculos-detail-component">
              <label htmlFor="nombre">Cédula</label>
              <input type="text" />
            </div>
            <div className="input-form-right-container-cotizacion-vehiculos-detail-component">
              <label htmlFor="nombre">Ciudad</label>
              <input type="text" />
            </div>
          </div>
          <div className="subtitle-right-container-cotizacion-vehiculos-detail-component">
            ¡Quiero agendar un Test Drive!
          </div>
          <div className="radio-right-container-cotizacion-vehiculos-detail-component">
            <div>
              <input type="radio" name="test-drive" id="" />
              <label htmlFor="">Sí quiero</label>
            </div>
            <div>
              <input type="radio" name="test-drive" id="" />
              <label htmlFor="">Ahora no</label>
            </div>
          </div>
          <div className="politicas-right-container-cotizacion-vehiculos-detail-component">
            <div>
              <input type="checkbox" name="politicas-1" id="" />
            </div>
            <div>
              <label htmlFor="">
                Acepto política de datos, términos y condiciones.
              </label>
            </div>
          </div>
          <div className="politicas-right-container-cotizacion-vehiculos-detail-component">
            <div>
              <input type="checkbox" name="politicas-1" id="" />
            </div>
            <div>
              <label htmlFor="">
                Autorizo la transferencia y transmisión de mis datos para las
                finalidades de publicidad a las compañías de su grupo.
              </label>
            </div>
          </div>
          <div className="text-politicas-right-container-cotizacion-vehiculos-detail-component">
            Estamos comprometidos con tu privacidad, por ello, en Hyundai
            Colombia S.A.S. junto con nuestros aliados oficiales te aseguramos
            que utilizaremos la información que nos proporcionas para
            comunicarnos contigo acerca de nuestros productos, servicios,
            ofertas, invitaciones, encuestas, entre otras finalidades y
            contenidos relevantes para ti. Puedes darte de baja de estas
            comunicaciones en cualquier momento. Para obtener más información
            consulta nuestra
          </div>
          <div className="btn-right-container-cotizacion-vehiculos-detail-component">
            Cotizar
          </div>
        </div>
      </div>
    </div>
  );
};

export default CotizacionVehiculosComponent;
