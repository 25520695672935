import React, { useState } from "react";
import logo_left from "../../assets/01_Home/Icon_Left.svg";
import logo_right from "../../assets/01_Home/Icon_Right.svg";

/*eslint-disable */

declare const window: any;

const images: any = [
  {
    link: "./assets/01_Home/Ioniq5_4K-2.webp",
    thumb: "./assets/01_Home/Ioniq5_4K-2.webp",
    type: "img",
    title: {
      label: "IONIQ 5",
      color: "#FFF",
      positionV: "top",
      positionH: "center",
      size: "large",
    },
    sello: {
      asset: "./assets/00_Sellos/IONIQ5.png",
      positionV: "bottom",
      positionH: "center",
      size: "small",
    },
  },
  {
    link: "./assets/01_Home/Tucson_4K.webp",
    thumb: "./assets/01_Home/Tucson_4K.webp",
    type: "img",
    title: {
      label: "Tu nueva TUCSON es un paso a la libertad sin límites.",
      color: "#FFF",
      positionV: "top",
      positionH: "left",
      size: "medium",
    },
    sello: {
      asset: "./assets/00_Sellos/TUCSON.png",
      positionV: "bottom",
      positionH: "left",
      size: "small",
    },
    tarjeta: {
      asset: "./assets/00_Sellos/TUCSON.png",
      positionV: "bottom",
      positionH: "left",
      size: "small",
    },
  },
  {
    link: "./assets/01_Home/Ioniq 5Optimized.mp4",
    thumb: "./assets/01_Home/image.png",
    type: "video",
  },
];

const BannerComponent: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="banner-home-component">
      <div
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        className="slider-banner-home-component"
      >
        {images.map((image: any, index: number) => {
          console.log(image.type === "img", image);

          return image.type === "img" ? (
            <div
              key={index}
              className={`active-img-banner-home-component ${index === 1 ? "with-margin" : ""}`}
              style={{ backgroundImage: `url(${image.link})`}}
            >
              {index === 0 ? (
                <div
                  style={{
                    display: "grid",
                    justifyItems: "center",
                    alignContent: "space-between",
                    width: "100%",
                    height: "100%"
                  }}
                >
                  <div className="large-title-active-img-banner-home-component">
                    {image.title.label}
                  </div>
                  <div className="individual-sello-active-img-banner-home-component">
                    <img src={image.sello.asset} alt="" />
                  </div>
                </div>
              ) : index === 1 ? (
                <div
                  style={{
                    display: "grid",
                    justifyItems: "start",
                    alignContent: "space-between",
                    width: "100%",
                    height: "100%"
                  }}
                >
                  <div className="medium-title-active-img-banner-home-component">
                    {image.title.label}
                  </div>
                  <div className="tarjeta-sello-active-img-banner-home-component">
                    <img src={image.sello.asset} alt="" />
                    <div>
                      <span className="precio-desde-tarjeta-sello-active-img-banner-home-component">Precio desde</span>
                      <span className="precio-tarjeta-sello-active-img-banner-home-component">$000.000.000*</span>
                      <span className="descripcion-tarjeta-sello-active-img-banner-home-component">Si financias con Banco Santander *Bono de pico y placa por 6 meses en bogotá**</span>
                      <span className="terminos-tarjeta-sello-active-img-banner-home-component">Aplican Términos y condiciones</span>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <video
              key={index}
              className="active-video-banner-home-component"
              src={image.link} // La URL local del video
              autoPlay
              muted
              loop
              playsInline
            />
          );
        })}
      </div>

      <div className="control-banner-home-component">
        <div
          className="left-control-banner-home-component"
          onClick={() => goToPrevious()}
        >
          <img src={logo_left} alt="" />
        </div>
        <div className="center-control-banner-home-component">
          {images.map((image: any, index: number) => (
            <div onClick={() => setCurrentIndex(index)}>
              <img src={image.thumb} alt="" />
              <div
                className={`mascara-center-control-banner-home-component ${
                  currentIndex === index ? "active" : ""
                }`}
              ></div>
            </div>
          ))}
        </div>
        <div
          className="right-control-banner-home-component"
          onClick={() => goToNext()}
        >
          <img src={logo_right} alt="" />
        </div>
      </div>
    </div>
  );
};

export default BannerComponent;
