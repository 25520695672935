import React from "react";

/*eslint-disable */

declare const window: any;

interface BannerVehiculosComponentProps {
  banner: any
}

const BannerVehiculosComponent: React.FC<BannerVehiculosComponentProps> = ({banner}) => {
  return (
    <div
      className="banner-vehiculos-detail-component"
      style={{ backgroundImage: `url(${banner.src})` }}
    >
      <div className="title-banner-vehiculos-detail-component">{banner.title}</div>
      {banner.ICSH !== null ? <div className="experiencia-banner-vehiculos-detail-component">Ver experiencia en 3D</div> : null}
    </div>
  );
};

export default BannerVehiculosComponent;
