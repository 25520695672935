import React from "react";
import { Link } from "react-router-dom";

/*eslint-disable */

declare const window: any;

const OtrosVehiculosComponent: React.FC = () => {
  return (
    <div className="otros-vehiculos-detail-component">
      <div className="title-otros-vehiculos-detail-component">
        Oportunidades para este vehículo
      </div>
      <div className="opciones-otros-vehiculos-detail-component">
        <div
          className={`item-opciones-otros-vehiculos-detail-component`}
          style={{
            backgroundImage: "URL(../assets/01_Home/Thumbnail_Simulador.webp)",
          }}
        >
          <div
            className={`mascara-item-opciones-otros-vehiculos-detail-component`}
          >
            <div className="title-mascara-item-opciones-otros-vehiculos-detail-component">
              Simula tu cuota
            </div>
            <Link to="#" className="btn-page-other-page-home-component">Conocer</Link>
          </div>
        </div>
        <div
          className={`item-opciones-otros-vehiculos-detail-component`}
          style={{
            backgroundImage: "URL(../assets/01_Home/Thumbnail_Concesionarios.webp)",
          }}
        >
          <div
            className={`mascara-item-opciones-otros-vehiculos-detail-component`}
          >
            <div className="title-mascara-item-opciones-otros-vehiculos-detail-component">
            Concesionarios
            </div>
            <Link to="#" className="btn-page-other-page-home-component">Conocer</Link>
          </div>
        </div>
        <div
          className={`item-opciones-otros-vehiculos-detail-component`}
          style={{
            backgroundImage: "URL(../assets/01_Home/Thumbnail_Repuestos.webp)",
          }}
        >
          <div
            className={`mascara-item-opciones-otros-vehiculos-detail-component`}
          >
            <div className="title-mascara-item-opciones-otros-vehiculos-detail-component">
            Repuestos genuinos
            </div>
            <Link to="#" className="btn-page-other-page-home-component">Conocer</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtrosVehiculosComponent;
